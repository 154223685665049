$(document).ready(function(){
  // TRIGGER MENU
  $('#trigger').click(function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('#menu ul.menu').slideUp();
    }else{
      $(this).addClass('open');
      $('#menu ul.menu').slideDown();

      $('#menu ul.menu li a').click(function(){
        $('#menu ul.menu').slideUp();
        $('#trigger').removeClass('open');
      });
    }
  });


  //BXSLIDER HOMEPAGE
  var oneSlider = ($('.bannerimage').children().length < 2);
  var slider = $('.bannerimage').bxSlider({
    mode: 'fade',
    auto: (!oneSlider),
    pause: 8000,
    easing: 'ease-in-out',
    speed: 1000,
    pager: false,
    controls: (!oneSlider),
    touchEnabled: (!oneSlider),
  });
  $('#headerbanner .bx-wrapper').append('<div id="trianglewrapper"><div class="triangle"></div></div>');
  function triangle() {
    $("#trianglewrapper .triangle").css({
      "border-right": $(window).width() + "px solid #fff",
      "border-top": "100px solid transparent"
    });
  }
  triangle();
  $(window).on('resize', triangle);



  //ABOUT TRIANGLE HOMEPAGE
  function triangleabout() {
    $("#trianglewrapperabout .triangleabout").css({
      "border-left": $('#about .right #trianglewrapperabout').width() + "px solid #f7f7f7",
      "border-bottom": $('#about .right #trianglewrapperabout').height() + "px solid transparent",
    });
  }
  triangleabout();
  $(window).on('resize', triangleabout);


  //DIENSTEN H3 EQUAL HEIGHT HOMEPAGE
  function equalheightdiensten(){
    var max = -1;
    $("#diensten ul.dienstengrid li h3").each(function() {
        var h = $(this).outerHeight();
        max = h > max ? h : max;
    });
    $("#diensten ul.dienstengrid li h3").outerHeight(max);
  }
  equalheightdiensten();
  $(window).on('resize', equalheightdiensten);


  // FANCYBOX REALISATIES HOMEPAGE
  $(".fancyboxattachments").fancybox({
    helpers: {
      overlay: {
        locked: false
      }
    }
  });


  //FOOTER TRIANGLE HOMEPAGE
  function trianglefooter() {
    $("#trianglewrapperfooter .trianglefooter").css({
      "border-right": $('#contactfooter').width()+40 + "px solid #f7f7f7",
      "border-top": "100px solid transparent",
    });
  }
  trianglefooter();
  $(window).on('resize', trianglefooter);



  // DIENSTEN REALISATIES LAZYLOAD + REALISATIES LAZYLOAD
  $('ul.realisatiegrid li .startlazyload').click(function() {
    $('ul.realisatiegrid li.hidden').fadeIn();
    $('ul.realisatiegrid li .lazy').lazy();
    $('ul.realisatiegrid li .startlazyload').parent().fadeOut();
  });


});
